import React from "react";

import CourseThumb from "../Shared/CourseThumb";
// import DealThumb from "../Shared/DealThumb";

import AdCard from "../Shared/AdSense/AdCard";

function Course({ course }) {
	return (
		<div
			style={{
				display: "grid",
				justifyContent: "center",
				justifyItems: "center",
				alignItems: "center",
				gridTemplateColumns: "repeat(auto-fit, minMax(310px, 1fr))",
				gap: "40px",
			}}
		>
			{course.map((element, index) => {
				if (element.detail[0]) {
					const course = {
						udemyId: element.udemyId,
						createdAt: element.createdAt,
						updatedAt: element.updatedAt,
						isFree: element.isFree,
						title: element.detail[0].title,
						image: element.detail[0].image_240,
						cleanUrl: element.cleanUrl,
						length: element.detail[0].lenghtTxt,
						rating: element.detail[0].rating,
						isPaid: element.detail[0].isPaid,
						price: element.detail[0].price / 100,
						updated: element.detail[0].updated,
						subscribers: element.detail[0].subscribers,
						locale: element.detail[0].locale.locale,
						instructorAvatar: element.instructor[0]?.image,
						instructorName: element.instructor[0]?.name,
						instructorCourses: element.instructor[0]?.course,
						instructorUrl: element.instructor[0]?.url,
					};
					return (
						<React.Fragment key={course.udemyId}>
							<CourseThumb course={course} />
							{index % 5 == 0 && process.env.AD_CARD === "true" && <AdCard />}
						</React.Fragment>
					);
				}
			})}
		</div>
	);
}

export default Course;
