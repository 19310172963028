import React from "react";

import CourseThumb from "../Shared/CourseThumb";
// import DealThumb from "../Shared/DealThumb";

import AdCard from "../Shared/AdSense/AdCard";

function Coupon({ coupons, deals }) {
	return (
		<div
			style={{
				display: "grid",
				justifyContent: "center",
				justifyItems: "center",
				alignItems: "center",
				gridTemplateColumns: "repeat(auto-fit, minMax(310px, 1fr))",
				gap: "40px",
			}}
		>
			{coupons.map((coupon, index) => {
				if (coupon.course.detail[0]) {
					const course = {
						udemyId: coupon.course.udemyId,
						title: coupon.course.detail[0].title,
						image: coupon.course.detail[0].image_240,
						cleanUrl: coupon.course.cleanUrl,
						length: coupon.course.detail[0].lenghtTxt,
						rating: coupon.course.detail[0].rating,
						isPaid: coupon.course.detail[0].isPaid,
						updated: coupon.course.detail[0].updated,
						subscribers: coupon.course.detail[0].subscribers,
						price: coupon.course.detail[0].price / 100,
						code: coupon.code,
						codeIsValid: coupon.isValid,
						couponCreatedAt: coupon.createdAt,
						discountValue: coupon.discountValue,
						discountPrice: coupon.discountPrice,
						maxUses: coupon.maxUses,
						remainingUses: coupon.remainingUses,
						endTime: coupon.endTime,
						locale: coupon.course.detail[0].locale.locale,
						instructorAvatar: coupon.course.instructor[0].image,
						instructorName: coupon.course.instructor[0].name,
						instructorCourses: coupon.course.instructor[0].course,
						instructorUrl: coupon.course.instructor[0].url,
					};

					return (
						<React.Fragment key={course.udemyId}>
							<CourseThumb course={course} />
							{index % 5 == 0 && process.env.AD_CARD === "true" && <AdCard />}
							{/* {index % 5 == 0 && process.env.AD_DEAL === "true" && deals && (
                <DealThumb
                  deal={deals[Math.floor(Math.random() * deals.length)]}
                />
              )} */}
						</React.Fragment>
					);
				}
			})}
		</div>
	);
}

export default Coupon;
