"use client"
import { useState, } from "react";
import { motion } from "framer-motion";
import Coupon from "./Coupon";
import Course from "./Course";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle, CardDescription	 } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { CountBadge } from "../Shared/CountBadge";
import { cn } from "@/helpers/utils";
import { Button } from "@/components/ui/button";

// Animation variants
const containerVariants = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			staggerChildren: 0.1
		}
	}
};

const itemVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.5
		}
	}
};

// Animation pour les cartes de cours
const courseVariants = {
	hidden: { opacity: 0, scale: 0.9 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.4
		}
	}
};

export function DailyView({ coupons, free, discount, expired }) {
	const [currentTab, setCurrentTab] = useState("100%OFF");
	
	// État pour gérer les éléments visibles par type
	const [visibleCoupons, setVisibleCoupons] = useState(coupons.slice(0, 10));
	const [visibleFree, setVisibleFree] = useState(free.slice(0, 10));
	const [visibleDiscount, setVisibleDiscount] = useState(discount.slice(0, 10));
	const [visibleExpired, setVisibleExpired] = useState(expired.slice(0, 10));
	
	// État pour le chargement
	const [loading, setLoading] = useState(false);
	
	// Éléments par page et tracking
	const ITEMS_PER_PAGE = 10;
	const [visibleItems, setVisibleItems] = useState({
		"100%OFF": 10,
		"FREE": 10,
		"DISCOUNT": 10,
		"EXPIRED": 10
	});
	
	const allTabs = [
		{
			name: "100%OFF",
			count: coupons.length,
			textColor: "text-yellow-500",
			bgColor: "bg-yellow-500",
			borderColor: "border-yellow-500",
			bgColorLight: "bg-yellow-100",
			activeClasses: "data-[state=active]:border-yellow-500 data-[state=active]:text-yellow-500 data-[state=active]:bg-yellow-50/20 dark:data-[state=active]:bg-yellow-950/20",
		},
		{
			name: "FREE",
			count: free.length,
			textColor: "text-green-500",
			bgColor: "bg-green-500",
			borderColor: "border-green-500",
			bgColorLight: "bg-green-100",
			activeClasses: "data-[state=active]:border-green-500 data-[state=active]:text-green-500 data-[state=active]:bg-green-50/20 dark:data-[state=active]:bg-green-950/20",
		},
		{
			name: "DISCOUNT",
			count: discount.length,
			textColor: "text-red-500",
			bgColor: "bg-red-500",
			borderColor: "border-red-500",
			bgColorLight: "bg-red-100",
			activeClasses: "data-[state=active]:border-red-500 data-[state=active]:text-red-500 data-[state=active]:bg-red-50/20 dark:data-[state=active]:bg-red-950/20",
		},
		{
			name: "EXPIRED",
			count: expired.length,
			textColor: "text-blue-500",
			bgColor: "bg-blue-500",
			borderColor: "border-blue-500",
			bgColorLight: "bg-blue-100",
			activeClasses: "data-[state=active]:border-blue-500 data-[state=active]:text-blue-500 data-[state=active]:bg-blue-50/20 dark:data-[state=active]:bg-blue-950/20",
		},
	];

	const tabs = allTabs.filter((tab) => {
		return tab.count > 0;
	});

	// Fonction pour gérer le changement de tab
	const handleTabChange = (value) => {
		setCurrentTab(value);
	};
	
	// Fonction pour charger plus d'éléments
	const loadMoreItems = () => {
		if (loading) return;
		
		setLoading(true);
		
		// Utiliser un timeout pour simuler un chargement asynchrone
		setTimeout(() => {
			const currentCount = visibleItems[currentTab];
			const newCount = currentCount + ITEMS_PER_PAGE;
			
			// Mettre à jour le nombre d'éléments visibles pour l'onglet actuel
			setVisibleItems(prev => ({
				...prev,
				[currentTab]: newCount
			}));
			
			// Mettre à jour les éléments visibles en fonction de l'onglet actuel
			switch (currentTab) {
				case "100%OFF":
					setVisibleCoupons(coupons.slice(0, newCount));
					break;
				case "FREE":
					setVisibleFree(free.slice(0, newCount));
					break;
				case "DISCOUNT":
					setVisibleDiscount(discount.slice(0, newCount));
					break;
				case "EXPIRED":
					setVisibleExpired(expired.slice(0, newCount));
					break;
			}
			
			setLoading(false);
		}, 300);
	};
	
	// Calculer s'il y a plus d'éléments à charger pour chaque tab
	const hasMore = {
		"100%OFF": visibleCoupons.length < coupons.length,
		"FREE": visibleFree.length < free.length,
		"DISCOUNT": visibleDiscount.length < discount.length,
		"EXPIRED": visibleExpired.length < expired.length
	};

	return (
		<div className="min-h-screen md:pb-6">
			<main className="pt-6 pb-12">
				<motion.div
					initial="hidden"
					animate="visible"
					variants={containerVariants}
					className="w-full max-w-[1700px] mx-auto"
				>
					<motion.div variants={itemVariants}>
						<Card className="mx-auto max-w-[1700px] border-2 border-th-primary-medium shadow-md hover:shadow-lg transition-shadow duration-300">
							<CardHeader className="pt-6 pb-4 text-center">
								<motion.div
									initial={{ y: -20, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									transition={{ duration: 0.5 }}
								>
									<div className="flex items-center justify-center">
										<CardTitle className="text-2xl font-medium text-th-accent-dark sm:text-3xl">
											<h1>Daily Freebies</h1>
										</CardTitle>
										<CountBadge count={coupons.length + free.length + discount.length + expired.length} />
									</div>
									<CardDescription className="mt-2 text-lg">
										Last 24 hours activity
									</CardDescription>
								</motion.div>
							</CardHeader>
							<Separator className="mb-0" />
							<CardContent className="p-0">
								<Tabs 
									value={currentTab}
									onValueChange={handleTabChange}
									className="w-full"
								>
									{/* Tabs pour mobile */}
									<motion.div 
										className="block p-4 md:hidden"
										variants={itemVariants}
									>
										<select
											id="tabs"
											name="tabs"
											className="block w-full py-2 pl-3 pr-10 text-base font-bold text-center border-2 rounded-md border-th-primary-medium bg-th-background text-th-accent-dark focus:outline-none focus:ring-th-accent-medium sm:text-sm "
											value={currentTab}
											onChange={(event) => {
												handleTabChange(event.target.value);
											}}
										>
											{tabs.map((tab) => (
												<option key={tab.name} value={tab.name}>
													{tab.name} ({tab.count})
												</option>
											))}
										</select>
									</motion.div>

									{/* TabsList pour desktop */}
									<motion.div 
										className="hidden border-b md:block border-th-primary-light"
										variants={itemVariants}
									>
										<TabsList className="justify-start w-full h-auto px-4 bg-transparent">
											{tabs.map((tab) => (
												<TabsTrigger
													key={tab.name}
													value={tab.name}
													className={cn(
														"px-6 py-4 rounded-none text-base font-medium border-b-2 border-transparent transition-all relative data-[state=active]:font-bold",
														tab.activeClasses
													)}
												>
													{tab.name}
													<span className="ml-2 inline-flex items-center justify-center rounded-full bg-th-background-light px-2.5 py-0.5 text-sm font-semibold text-th-accent-dark">
														{tab.count}
													</span>
													<span className={cn(
														"absolute bottom-0 left-0 w-full h-[3px] opacity-0 data-[state=active]:opacity-100 transition-opacity",
														tab.borderColor
													)}></span>
												</TabsTrigger>
											))}
										</TabsList>
									</motion.div>

									{/* Contenus des tabs */}
									<TabsContent value="100%OFF" className="p-4">
										<motion.div
											initial={{ opacity: 0, y: 10 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.3 }}
										>
											<Coupon coupons={visibleCoupons} />
											{hasMore["100%OFF"] && (
												<div 
													className="flex justify-center w-full py-4"
												>
													{loading && currentTab === "100%OFF" ? (
														<div className="flex space-x-2 animate-pulse">
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
														</div>
													) : (
														<Button 
															onClick={loadMoreItems}
															variant="outline" 
															className="mt-4"
														>
															Load More
														</Button>
													)}
												</div>
											)}
										</motion.div>
									</TabsContent>
									<TabsContent value="FREE" className="p-4">
										<motion.div
											initial={{ opacity: 0, y: 10 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.3 }}
										>
											<Course course={visibleFree} />
											{hasMore["FREE"] && (
												<div 
													className="flex justify-center w-full py-4"
												>
													{loading && currentTab === "FREE" ? (
														<div className="flex space-x-2 animate-pulse">
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
														</div>
													) : (
														<Button 
															onClick={loadMoreItems}
															variant="outline" 
															className="mt-4"
														>
															Load More
														</Button>
													)}
												</div>
											)}
										</motion.div>
									</TabsContent>
									<TabsContent value="DISCOUNT" className="p-4">
										<motion.div
											initial={{ opacity: 0, y: 10 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.3 }}
										>
											<Coupon coupons={visibleDiscount} />
											{hasMore["DISCOUNT"] && (
												<div 
													className="flex justify-center w-full py-4"
												>
													{loading && currentTab === "DISCOUNT" ? (
														<div className="flex space-x-2 animate-pulse">
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
														</div>
													) : (
														<Button 
															onClick={loadMoreItems}
															variant="outline" 
															className="mt-4"
														>
															Load More
														</Button>
													)}
												</div>
											)}
										</motion.div>
									</TabsContent>
									<TabsContent value="EXPIRED" className="p-4">
										<motion.div
											initial={{ opacity: 0, y: 10 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{ duration: 0.3 }}
										>
											<Coupon coupons={visibleExpired} />
											{hasMore["EXPIRED"] && (
												<div 
													className="flex justify-center w-full py-4"
												>
													{loading && currentTab === "EXPIRED" ? (
														<div className="flex space-x-2 animate-pulse">
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
															<div className="w-2 h-2 rounded-full bg-primary"></div>
														</div>
													) : (
														<Button 
															onClick={loadMoreItems}
															variant="outline" 
															className="mt-4"
														>
															Load More
														</Button>
													)}
												</div>
											)}
										</motion.div>
									</TabsContent>
								</Tabs>
							</CardContent>
						</Card>
					</motion.div>
				</motion.div>
			</main>

		</div>
	);
}